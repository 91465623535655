import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

const Blog = () => {

    return (
        <Container>
            <Row className='justify-content-creator'>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    );
}

export default Blog;